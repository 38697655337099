<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import FormDocument from '@/views/documents/FormDocument.vue'

export default {
  name: 'Index',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    VueGoodTable,
    vSelect,
    FormDocument,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'STT',
            field: 'rowNum',
            sortable: false,
            width: '5%',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'Nội dung',
            field: 'name',
            filterOptions: {
              enabled: true,
              placeholder: 'Tên file',
              trigger: 'enter',
            },
            sortable: false,
          },
          {
            label: 'Tên file',
            field: 'mediaName',
            sortable: false,
          },
          {
            label: 'Tải về',
            field: 'download',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10%',
            sortable: false,
          },
          // {
          //   label: 'Thao tác',
          //   field: 'action',
          //   thClass: 'text-center',
          //   tdClass: 'text-center',
          //   width: '10%',
          //   sortable: false,
          // },
        ],
        rows: [],
      },
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      itemsDisplay: [],
      dataSendForm: {},
    }
  },

  async created() {
    // init data FROM API
    await this.loadItems()
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      documents: 'documents/documents',
      total: 'documents/total',
    }),
  },
  watch: {
    // listening change data init

  },
  methods: {
    ...mapActions({
      getDocuments: 'documents/getDocuments',
      deleteDocument: 'documents/deleteDocument',

    }),
    // handle even
    updateParams(newProps) {
      // console.log(newProps)
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    // load items is what brings back the rows from server
    async loadItems() {
      this.isLoading = true
      const param = {
        currentPage: this.serverParams.page,
        itemsPerPage: this.serverParams.perPage,
        search: this.serverParams.columnFilters.name ? this.serverParams.columnFilters.name : '',
      }
      await this.getDocuments(param)
      console.log(this.documents)
      this.table.rows = this.documents
      this.isLoading = false
    },

    async onSuccessPopup() {

    },

    async deleteItem(data) {
      this.isLoading = true
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: '',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Xóa',
          cancelButtonText: 'Hủy',
        })
        .then(async result => {
          if (result.isConfirmed) {
            try {
              const response = await this.deleteDocument(data.id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.showToast('Đã xóa thành công!', 'CheckIcon', 'success')
                } else {
                  this.showToast(`Có lỗi xảy ra ${message}`, 'XCircleIcon', 'warning')
                }
              }
            } catch (e) {
              this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
            } finally {
              this.isLoading = false
              await this.loadItems()
            }
          }
        })
    },

    async editItem(data) {
      this.$bvModal.show('modal-document')
      this.dataSendForm = data
    },

    openModal() {
      this.dataSendForm = {
        id: null,
        rowNum: null,
        name: '',
        mediaId: null,
        mediaName: '',
        url: '',
        vgt_id: 0,
        originalIndex: 0,
      }
      this.$refs['modal-document'].show()
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    async saveDataSuccess(data) {
      if (data) {
        this.$bvModal.hide('modal-document')
        await this.loadItems()
      }
    },
  },
}
</script>

<style scoped>

</style>
